exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-categories-index-js": () => import("./../../../src/pages/categories/index.js" /* webpackChunkName: "component---src-pages-categories-index-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-pages-wiki-index-js": () => import("./../../../src/pages/wiki/index.js" /* webpackChunkName: "component---src-pages-wiki-index-js" */),
  "component---src-templates-article-item-js": () => import("./../../../src/templates/article-item.js" /* webpackChunkName: "component---src-templates-article-item-js" */),
  "component---src-templates-category-js": () => import("./../../../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-donate-page-js": () => import("./../../../src/templates/donate-page.js" /* webpackChunkName: "component---src-templates-donate-page-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-partner-item-js": () => import("./../../../src/templates/partner-item.js" /* webpackChunkName: "component---src-templates-partner-item-js" */),
  "component---src-templates-product-item-js": () => import("./../../../src/templates/product-item.js" /* webpackChunkName: "component---src-templates-product-item-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */),
  "component---src-templates-wiki-js": () => import("./../../../src/templates/wiki.js" /* webpackChunkName: "component---src-templates-wiki-js" */)
}

